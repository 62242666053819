<template>
  <mobile-screen
    :header="true"
    :screen-class="
      `icon-app1 gray-bg visitor-management-screen ${
        receptionDesks && receptionDesks.length === 1 ? 'page-hidden' : ''
      }`
    "
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: routeHelp
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "visitor-management",
              "reception-desks",
              "reception-desks"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>

    <section class="search-wrapper">
      <div class="search-field">
        <input
          type="text"
          @keyup="searchQuery = $event.target.value"
          :placeholder="
            displayLabelName('visitor-management', 'main', 'search')
          "
        />
        <div class="icon search">
          <icon icon="#cx-app1-search-16x16" width="16" height="16" />
        </div>
      </div>
    </section>

    <ul class="clebex-item-section pill">
      <li
        v-for="(desk, index) in receptionDesksList"
        :key="index"
        class="clebex-item-section-item"
      >
        <router-link
          :to="{
            name: routeReceptionDesk,
            params: {
              receptionDeskId: desk.id
            }
          }"
        >
          <div class="clebex-item-content-wrapper">
            <span class="label">
              <span class="highlight">{{ desk.name }}</span>
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-arrow-right-12x12"></icon>
              </span>
            </span>
          </div>
        </router-link>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import { preferenceCategoryIds } from "@/services/preference-options";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "VisitorManagement",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      searchQuery: "",
      userFormTemplate: preferenceCategoryIds.userFormTemplate,
      helpSlug: "visitor"
    };
  },
  created() {
    this.getReceptionDesks();
    // reception desk component will be filling rest of the screen estate
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true
    });
    this.getUserFormFields(this.userFormTemplate);
  },
  methods: {
    ...mapActions("visitorManagement", ["getReceptionDesks"]),
    ...mapActions("user", ["getUserFormFields"])
  },
  watch: {
    receptionDesks: {
      handler(desks) {
        if (desks && desks.length === 1) {
          this.$router.push({
            name: "r_visitor-management-reception-desk",
            params: { receptionDeskId: desks[0].id }
          });
        }
      }
    },
    $route: {
      deep: true,
      handler(route) {
        if (route.name !== "r_visitor-management") {
          return;
        }

        if (this.receptionDesks && this.receptionDesks.length === 1) {
          this.$router.push({
            name: "r_visitor-management-reception-desk",
            params: { receptionDeskId: this.receptionDesks[0].id }
          });
        }
      }
    }
  },
  computed: {
    ...mapState("visitorManagement", ["receptionDesks"]),
    ...mapState("user", ["userFormFields"]),
    receptionDesksList() {
      const query = this.searchQuery;
      if (this.receptionDesks && this.receptionDesks.length) {
        if (query && query.length > 0) {
          return this.receptionDesks.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.receptionDesks;
        }
      }
      return null;
    },
    routeReceptionDesk() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-reception-desk";
      }
      return "r_visitor-management-reception-desk";
    },
    routeHelp() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-visitor-help-online";
      }
      return "r_visitor-help-online";
    }
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true
    });
  }
};
</script>
